import React, {lazy, Suspense} from "react";
import {Redirect, Route, Switch} from "react-router-dom";

export function PrivateRoutes() {
  const UsersPage = lazy(() => import("../pages/users"));
  const LogoutPage = lazy(() => import("../pages/logout"));
  const ContactsPage = lazy(() => import("../pages/contacts"));
  const DashboardPage = lazy(() => import("../pages/dashboard"));
  const CompaniesPage = lazy(() => import("../pages/companies"));
  const CommunitiesPage = lazy(() => import("../pages/communities"));

  const SkillsPage = lazy(() => import("../pages/skills"));
  const ServiceTypes = lazy(() => import("../pages/serviceTypes"));
  const AreaOfStudyPage = lazy(() => import("../pages/areaOfStudy"));
  const AreaOfExpertises = lazy(() => import("../pages/areaOfExpertises"));

  return (
    <Suspense fallback={<div className='p-6'>Loading...</div>}>
      <Switch>
        <Route path='/users' component={UsersPage} />
        <Route path='/contacts' component={ContactsPage} />
        <Route path='/companies' component={CompaniesPage} />
        <Route path='/communities' component={CommunitiesPage} />

        <Route path='/skills' component={SkillsPage} />
        <Route path='/serviceTypes' component={ServiceTypes} />
        <Route path='/areaOfStudy' component={AreaOfStudyPage} />
        <Route path='/areaOfExpertises' component={AreaOfExpertises} />

        <Route path='/logout' component={LogoutPage} />

        <Route path='/' component={DashboardPage} />

        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  );
}
