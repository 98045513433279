import {Card} from "antd";
import React, {lazy} from "react";
import {Redirect, Route, Switch} from "react-router-dom";

export function Index() {
  const Login = lazy(() => import("./components/login"));

  return (
    <div className='d-flex h-screen justify-content-center align-items-center'>
      <Card className='shadow'>
        <Switch>
          <Route path='/auth/login' component={Login} />
          <Redirect from='/login' exact={true} to='/auth/login' />
          <Redirect from='/auth' exact={true} to='/auth/login' />
          <Redirect to='/auth/login' />
        </Switch>
      </Card>
    </div>
  );
}
