import React, {Suspense} from "react";
import {BrowserRouter} from "react-router-dom";

import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "antd/dist/antd.css";
import "./styles/app.scss";

import {Routes} from "./routing/Routes";

function App({basename}) {
  return (
    <Suspense fallback={<small className='d-block p-6'>Loading...</small>}>
      <BrowserRouter basename={basename}>
        <Routes />
      </BrowserRouter>

      <ToastContainer />
    </Suspense>
  );
}

export default App;
