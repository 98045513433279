import {Layout, Menu} from "antd";
import {Link, useLocation} from "react-router-dom";
import {
  ContactsOutlined,
  DashboardOutlined,
  FolderOutlined,
  LogoutOutlined,
  ShopOutlined,
  SolutionOutlined,
  TeamOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";

const {Content, Sider} = Layout;
const {SubMenu} = Menu;

function Dashboard({children}) {
  const location = useLocation();
  const activeKey = location.pathname.replace("/", "");

  return (
    <Layout>
      <Sider
        width={250}
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
        }}
      >
        <img src='/logo-white.svg' className='mt-5 mb-4 px-4 w-full' style={{maxWidth: 230}} alt='birdflocks' />

        <Menu theme='dark' mode='inline' defaultActiveFirst defaultSelectedKeys={activeKey}>
          <Menu.Item key='dashboard' icon={<DashboardOutlined />}>
            <Link to='/dashboard'>Dashboard</Link>
          </Menu.Item>
          <Menu.Item key='users' icon={<TeamOutlined />}>
            <Link to='/users'>Users</Link>
          </Menu.Item>
          <Menu.Item key='communities' icon={<SolutionOutlined />}>
            <Link to='/communities'>Communities</Link>
          </Menu.Item>
          <Menu.Item key='companies' icon={<ShopOutlined />}>
            <Link to='/companies'>Companies</Link>
          </Menu.Item>
          <SubMenu key='skillsSub' icon={<FolderOutlined />} title='All Fields'>
            <SubMenu key='areaOfExpertisesSub' icon={<FolderOutlined />} title='Area Of Studies'>
              <Menu.Item key='areaOfExpertisesIn' icon={<UnorderedListOutlined />}>
                <Link to='/areaOfExpertises/individuals'>Individuals</Link>
              </Menu.Item>
              <Menu.Item key='areaOfExpertisesCo' icon={<UnorderedListOutlined />}>
                <Link to='/areaOfExpertises/companies'>Companies</Link>
              </Menu.Item>
            </SubMenu>
            <Menu.Item key='skills' icon={<UnorderedListOutlined />}>
              <Link to='/skills'>Skills</Link>
            </Menu.Item>
            <Menu.Item key='serviceTypes' icon={<UnorderedListOutlined />}>
              <Link to='/serviceTypes'>Service Types</Link>
            </Menu.Item>
            <Menu.Item key='areaOfStudy' icon={<UnorderedListOutlined />}>
              <Link to='/areaOfStudy'>Area Of Studies</Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item key='contacts' icon={<ContactsOutlined />}>
            <Link to='/contacts'>Contact</Link>
          </Menu.Item>
          <Menu.Item key='logout' icon={<LogoutOutlined />}>
            <Link to='/logout'>Logout</Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className='site-layout h-screen overflow-auto' style={{marginLeft: 250}}>
        <Content className='p-4'>
          <div className='container m-auto'>{children}</div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default Dashboard;
