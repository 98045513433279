/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */
import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";

import MasterLayout from "../layout/dashboard";
import EmptyLayout from "../layout/empty";

import {Index as AuthPage} from "../pages/auth";
import {PrivateRoutes} from "./PrivateRoutes";
import {Index as ErrorPage} from "../pages/errors";

const Routes = () => {
  const isAuthorized = localStorage.getItem("token");
  return (
    <Switch>
      {!isAuthorized ? (
        <EmptyLayout>
          <AuthPage />
        </EmptyLayout>
      ) : (
        <Redirect from='/auth' to='/' />
      )}

      <Route path='/error' component={ErrorPage} />

      <MasterLayout>
        <PrivateRoutes />
      </MasterLayout>
    </Switch>
  );
};

export {Routes};
